<script>
import Vue from 'vue';
import { MODULE_NAME as AUTH_MODULE, ACTIONS as AUTH_ACTIONS } from '@/store/modules/auth/types';
import { mapActions } from 'vuex';
import { logout } from '@/services/users';

export default Vue.extend({
  name: 'Logout',

  beforeMount() {
    this.logout();
  },

  render() {
    return '';
  },

  methods: {
    ...mapActions(AUTH_MODULE, {
      signUserOut: AUTH_ACTIONS.SIGN_USER_OUT,
    }),

    async logout() {
      await logout();

      this.signUserOut();

      this.$router.push({ name: 'home' });
    },
  },
});
</script>
